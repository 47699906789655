import React from 'react';
import PropTypes from 'prop-types';

const CrossIcon = ({ fill = "#ffffff", width = "24px", height = "24px", stroke = "#ffffff", strokeWidth = "25px", ariaLabel = "Close icon" }) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 490 490"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={ariaLabel}
  >
    <defs>
      <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
        <feDropShadow dx="25" dy="25" stdDeviation="30" floodColor="#000000" />
      </filter>
    </defs>
    <polygon
      points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
      strokeLinecap="round"
      filter="url(#shadow)"
    />
  </svg>
);

CrossIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default CrossIcon;
