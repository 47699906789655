import React, { useState } from "react";
import PinIcon from "./icons/PinIcon";

const ToggleMap = ({ togglePins }) => {
  const [isShowing, setIsShowing] = useState(true);

  const handleTogglePins = () => {
    togglePins(!isShowing);
    setIsShowing(!isShowing);
  };

  return (
    <div className="hidden sm:flex lg:flex fixed h-14 bottom-[4rem] right-[2rem] lg:right-[8rem] items-center gap-[1rem] z-50 ">
      <div className="flex h-full items-center bg-[#205479] rounded-full px-[1.5rem] py-[1rem]">
        <div className="flex flex-row items-center gap-[1rem]">
          <div className="flex flex-row items-center">
            <PinIcon fill="white" width="24" height="24" />
            <div
              className={`w-[3rem] h-[1.5rem] flex items-center rounded-full p-1 transition-colors duration-300 ml-2 ${
                isShowing ? "bg-[#FFFFFF]" : "bg-[#9F9F9F]"
              }`}
              onClick={handleTogglePins}
            >
              <div
                className={`${
                  isShowing ? "bg-[#FFC85A]" : "bg-[#205479]"
                } w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
                  isShowing ? "translate-x-5" : "translate-x-0"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleMap;
