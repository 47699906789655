import React from "react";
import { useTranslation } from "react-i18next";

const AniTokenomicUseCase = () => {
  const { t } = useTranslation();
  
  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative w-screen max-w-[1920px] z-[30] flex flex-col items-center py-[8rem] mt-[-5px] bg-[#000000]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="hidden opacity-90 lg:flex absolute inset-0 z-[-1]">
        <img
          className="w-screen object-cover"
          src="/assets/anitoken/aniusecase-bg.webp"
          alt="Background"
        />
      </div>

      {/* Gradient background */}
      <div className="absolute opacity-40 inset-0 bg-gradient-to-b from-[#000000] from-0% via-[#0041BF] via-74% to-[#000000] to-100% pointer-events-none z-[-1]"></div>
      <div className="absolute opacity-80 inset-0 bg-gradient-to-b from-[#000000] from-0% via-[#00114E] via-49% to-[#000000] to-100% pointer-events-none z-[-1]"></div>

      <img
        className="flex lg:hidden absolute right-[5%] top-[2%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[60px] floating-element"
        src="/assets/anitoken/usecase-mobile-coin.webp"
        alt=""
      />

      <img
        className="absolute left-[13%] lg:left-[20%] top-[10%] lg:top-[8%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[27px] lg:w-[60px] floating-element"
        src="/assets/anitoken/anicoin-usecase-coin-1.webp"
        alt=""
      />

      <img
        className="absolute left-[8%] lg:left-[12%] top-[12%] lg:top-[13%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[27px] lg:w-[60px] floating-element"
        src="/assets/anitoken/anicoin-usecase-coin-2.webp"
        alt=""
      />

      <img
        className="absolute right-[20%] lg:right-[20%] bottom-[12%] lg:bottom-[15%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[42px] lg:w-[60px] floating-element"
        src="/assets/anitoken/anicoin-usecase-coin-3.webp"
        alt=""
      />

      <img
        className="absolute right-[12%] lg:right-[17%] bottom-[10%] lg:bottom-[10%] transform -translate-x-1/2 -translate-y-1/2 z-[200] w-[42px] lg:w-[60px] floating-element"
        src="/assets/anitoken/anicoin-usecase-coin-4.webp"
        alt=""
      />

      <div className="flex flex-col justify-center items-center gap-[1rem]">
        <h1 className="text-center text-5xl lg:text-5xl text-[#FFFFFF] font-normal font-bigNoodle tracking-wider uppercase">
          {t("ani usecase")}
        </h1>
      </div>

      <div className="w-full hidden lg:flex justify-center mt-[5rem] z-[100]">
        <img
          className="w-[80%] object-cover"
          src="/assets/anitoken/aniusecase.webp"
          alt="element"
        />
      </div>

      <div className="w-full flex lg:hidden justify-center mt-[5rem] z-[100]">
        <img
          className="w-full object-cover"
          src="/assets/anitoken/aniusecase-mobile.webp"
          alt="element"
        />
      </div>

    </div>
  );
};

export default AniTokenomicUseCase;
