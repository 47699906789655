import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ChevronLeftStyled from "../../component/icons/ChevronLeftStyled";
import ChevronRightStyled from "../../component/icons/ChevronRightStyled";

const MainV2EarnEvent = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('TAP_2_EARN_EVENT');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={`${index}-${part}`}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  const eventList = [
    {
        eventId: "PLAY_TO_EARN_EVENT",
        bgSrc: "https://storage.animara.world/showcase-play-to-earn.mp4",
        posterSrc: "/assets/mainV2/axo_postert_01",
        mobilePoster: "/assets/mainV2/mobile_postert_01",
        posterTitle: formatStringBreaks(t("event card title 1")),
        eventTitle: t("event card title 1"),
        eventDescription: t("event card info desc 1"),
        isComingSoon: true
    },
    {
        eventId: "HUNT_TO_EARN_EVENT",
        bgSrc: "https://storage.animara.world/showcase-hunt-to-earn.mp4",
        posterSrc: "/assets/mainV2/axo_postert_02",
        mobilePoster: "/assets/mainV2/mobile_postert_02",
        posterTitle: formatStringBreaks(t("event card title 2")),
        eventTitle: t("event card title 2"),
        eventDescription: t("event card info desc 2"),
        isComingSoon: true
    },
    {
        eventId: "TAP_2_EARN_EVENT",
        bgSrc: "https://storage.animara.world/showcase-tap-to-earn.mp4",
        posterSrc: "/assets/mainV2/axo_postert_03",
        mobilePoster: "/assets/mainV2/mobile_postert_03",
        posterTitle: formatStringBreaks(t("event card title 3")),
        eventTitle: t("event card title 3"),
        eventDescription: t("event card info desc 3"),
        isComingSoon: false
    },
  ];

  const handleChangeEvent = (event) => {
    setCurrentTab(event);
  }

  const handleMobileChangeEvent = (direction) => {
    if(direction === "prev"){
        switch(currentTab){
            case "PLAY_TO_EARN_EVENT":
                setCurrentTab("TAP_2_EARN_EVENT");
                break;
            case "HUNT_TO_EARN_EVENT":
                setCurrentTab("PLAY_TO_EARN_EVENT");
                break;
            case "TAP_2_EARN_EVENT":
                setCurrentTab("HUNT_TO_EARN_EVENT");
                break;
            default:
                break;
        };
    }else if(direction === "nxt"){
        switch(currentTab){
            case "PLAY_TO_EARN_EVENT":
                setCurrentTab("HUNT_TO_EARN_EVENT");
                break;
            case "HUNT_TO_EARN_EVENT":
                setCurrentTab("TAP_2_EARN_EVENT");
                break;
            case "TAP_2_EARN_EVENT":
                setCurrentTab("PLAY_TO_EARN_EVENT");
                break;
            default:
                break;
        };
    }
  }

  return (
    <div className="relative h-auto lg:h-full w-full pb-16 max-w-[1920px] lg:overflow-hidden">

        {/* Start and End Fade Out Overlays */}
        <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
        <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

        <div className="hidden lg:flex absolute top-0 left-0 pointer-events-none pt-[-40] z-[30]">
            <img
                className="w-full h-full object-cover"
                src="/assets/mainV2/main-earn-event-bg.webp"
                alt="Event Background"
            />
        </div>
        <div className="flex lg:hidden absolute top-0 left-0 pointer-events-none mt-[-20] z-[15]">
            <img
                className="w-screen h-[900px] object-top object-cover"
                src="/assets/mainV2/earn-event-mobile-bg.webp"
                alt="Event Background"
            />
        </div>

        {eventList.map(item => {
            const isActive = item.eventId === currentTab;
            return(
                <div
                    key={item.eventId} 
                    className={`lg:absolute w-[100%] h-[55vh] lg:h-auto lg:top-[-10%] pointer-events-none z-[10] lg:z-[20] transition-all duration-300 ${isActive ? "relative lg:absolute opacity-1 lg:right-[-20%]" : "absolute opacity-0 right-[30%]"}`}>
                    {!isMobile ? (
                    <video
                        key={`event-vid-${item.eventId}`}
                        className={`w-full h-full object-cover z-[100]`}
                        src={`${item.bgSrc}`}  
                        autoPlay
                        muted
                        loop
                        playsInline
                    />
                    ) : (
                    <img
                        className="w-full h-auto object-cover"
                        src={`${item.mobilePoster}.webp`}
                        alt="Event Background"
                    />
                    )}
                </div>
            )
        })}

        <div className="relative flex flex-col lg:flex-row h-fit lg:h-full lg:px-[5rem] justify-start items-center lg:items-end pb-12 lg:pb-0">
            <div className="absolute inset-0 mt-[5%] px-[1rem] w-100 flex lg:hidden flex-row justify-between">
                <div
                    className=" z-[1001]"
                    onClick={() => { handleMobileChangeEvent("prev") }}
                >
                    <ChevronLeftStyled fill="#ffffff" width="40" height="40" filter={false}/>
                </div>
                <div
                    className=" z-[1001]"
                    onClick={() => { handleMobileChangeEvent("nxt") }}
                >
                    <ChevronRightStyled fill="#ffffff" width="40" height="40" filter={false}/>
                </div>
            </div>
            <div className="flex lg:hidden justify-center items-end w-[100%] lg:h-full z-[1000]">
                {eventList.map(item => {
                    const isActive = item.eventId === currentTab;
                    if (isActive) {
                        return (
                            <div 
                                key={`mobile-event-details-${item.eventId}`}
                                className="flex flex-col items-center w-[70%] lg:w-[80%]">
                                <h1 className="text-[#FFC85A] text-5xl font-normal font-bigNoodle transition-opacity duration-1000">
                                    {item.eventTitle}
                                </h1>
                                <p className="text-center text-white text-sm font-medium font-outfit mt-[1rem]">
                                    {item.eventDescription}
                                </p>
                                <div className="flex gap-[1rem] items-center my-[1rem]">
                                    <a
                                        type="button"
                                        href="https://app.animara.world/login"
                                        disabled={item.isComingSoon}
                                        className={`min-w-[200px] px-[3rem] py-[1rem] ${item.isComingSoon ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#FFB23F] hover:scale-105'} drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl text-center font-outfit font-semibold xl:font-bold z-[30]`}
                                    >
                                        {item.isComingSoon ? t("Coming Soon") : t("Play Now")}
                                    </a>
                                </div>
                                <div className="flex gap-[1rem] items-center">
                                    <a
                                        type="button"
                                        href="/aniearn"
                                        className="min-w-[200px] px-[3rem] py-[1rem] bg-[#0163be] hover:scale-105 drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30] transition-all duration-300"
                                    >
                                        {t("discover now")}
                                    </a>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>

            <div className="hidden lg:flex flex-col lg:flex-row mt-[2rem] mb-[4rem] lg:mb-0 lg:py-[5rem] gap-[1rem] w-[80%] lg:w-[40%] z-[1000]">
                {eventList.map(item => {
                    const isActive = item.eventId !== currentTab;
                    if(isActive){
                        return(
                            <div
                                key={`desktop-event-poster-${item.eventId}`}
                                className="relative w-full lg:w-  [50%] h-[282px] lg:h-[100%]  group"
                                onClick={() => { handleChangeEvent(item.eventId) }}>
                                <img
                                    className="w-[100%] h-[100%] object-cover object-center opacity-50 lg:opacity-75"
                                    src={`${item.posterSrc}.webp`} 
                                    alt={item.posterTitle}
                                />
                                <img
                                    className="absolute inset-0 w-[100%] h-[100%] opacity-0 transition-opacity duration-300 group-hover:opacity-70"
                                    src="/assets/mainV2/event-card-hover-bg.webp"
                                    alt="hover bg"
                                />
                                <div className="absolute inset-0 bg-gradient-overlay opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                                <div className="flex flex-col gap-[1rem] absolute left-1/2 bottom-0 transform -translate-x-1/2 my-10 pb-5 w-[80%] border-solid border-b-2 border-[#FFF7EA] text-[#FFC85A] transition-all group-hover:border-none">
                                    <div className="flex justify-between items-end w-full">
                                        <p
                                            className="text-5xl font-normal font-bigNoodle transition-opacity duration-1000"
                                            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                                        >
                                            {item.posterTitle}
                                        </p>
                                        <img
                                            className="rotate-90 w-[25px] h-auto opacity-0 mb-[-10px] transition-all duration-500 group-hover:opacity-100 group-hover:mb-[10px]"
                                            src="/assets/icons/event-hover-arrow.webp"
                                        />
                                    </div>
                                    <p className="text-white text-xs font-regular font-outfit opacity-0 h-[0px] mb-[-1rem] transition-all duration-700 group-hover:opacity-100 group-hover:h-fit group-hover:mb-[0]">
                                        {item.eventDescription}
                                    </p>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
            <div className="hidden lg:flex justify-center items-end pb-[6.5%] w-[60%] h-full z-[1000]">
                {eventList.map(item => {
                    const isActive = item.eventId === currentTab;
                    if(isActive){
                        return(
                            <div key={`desktop-event-details-${item.eventId}`}className="flex flex-col w-[75%]">
                                <h1
                                    className="text-[#FFC85A] text-6xl font-normal font-bigNoodle transition-opacity duration-1000"
                                    style={{ textShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)' }}
                                >
                                    {item.eventTitle}
                                </h1>
                                <p
                                    className="text-white text-base font-medium font-outfit mt-[1rem]"
                                    style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
                                >
                                    {item.eventDescription}
                                </p>
                                <div className="flex gap-[1rem] items-center mt-[1rem]">
                                    <a 
                                        type="button"
                                        href="https://app.animara.world/login"
                                        disabled={item.isComingSoon}
                                        className={`min-w-[220px] px-[1rem] lg:px-[3rem] py-[0.5rem] lg:py-[1rem] ${item.isComingSoon ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#FFB23F] hover:scale-105'} drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl text-center font-outfit font-semibold xl:font-bold transition-all duration-300`}
                                        >
                                        {item.isComingSoon ? t("Coming Soon") : t("Play Now")}
                                    </a>
                                    <a
                                        type="button"
                                        href="/aniearn"
                                        className="min-w-[220px]  px-[3rem] py-[1rem] bg-[#0163be] hover:scale-105 drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30] transition-all duration-300"
                                    >
                                        {t("discover now")}
                                    </a>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    </div>
  );
};

export default MainV2EarnEvent;
