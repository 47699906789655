import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronDown from "../../component/icons/ChevronDown";

const AniPaperRoadmap = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const roadmapPointList = [
    {
      date: t("roadmap date 1"),
      title: t("roadmap title 1"),
      desc: t("roadmap title desc 1"),
      content: t("roadmap desc 1"),
    },
    {
      date: t("roadmap date 2"),
      title: t("roadmap title 2"),
      desc: t("roadmap title desc 2"),
      content: t("roadmap desc 2"),
    },
    {
      date: t("roadmap date 3"),
      title: t("roadmap title 3"),
      desc: t("roadmap title desc 3"),
      content: t("roadmap desc 3"),
    },
  ];

  const formatData = (data) => {
    if (!data) return null;
  
    return data.split("\n").map((item, index) => {
      const regex = /\b([A-Z]{2,})\b/g;
      const formattedContent = item.split(regex).map((word, idx) => {
        if (regex.test(word)) {
          const numberingReplace = word.replace(/\bone\b/i, "1");
          return (
            <span
              key={`capital-${index}-${idx}`}
              className="text-[#FFFFFF] text-2xl lg:text-3xl font-normal font-bigNoodle"
            >
              {numberingReplace + " "}
            </span>
          );
        } else if (word.trim() !== "") {
          const capitalizedContent = word.replace(/\bui\b/i, "UI").replace(/\bnft\b/i, "NFT").replace(/\bani\b/i, "ANI");
          const listing = capitalizedContent.split("**");
          return (
            <div className="mb-[2rem]" key={`content-${index}-${idx}`}>
              {listing.map((listItem, listIdx) => (
                <li className="mb-[1rem]" key={`listing-${index}-${listIdx}`}>
                  <span className="text-sm lg:text-base font-light mb-2 text-[#C5C5C5]">
                    {listItem}
                  </span>
                </li>
              ))}
            </div>
          );
        } else {
          return <span className="mt-[1rem]" key={`empty-${index}-${idx}`}></span>;
        }
      });
  
      return (
        <React.Fragment key={`fragment-${index}`}>
          <div>{formattedContent}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="relative h-auto w-screen max-w-[1920px] z-[30] flex flex-col items-center bg-[#000000]">
      <div className="block absolute top-0 left-0 w-[50px] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[50px] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="relative w-full h-auto">
        <h1 className="absolute top-[-1%] md:top-[40px] lg:top-[-8%] left-1/2 transform -translate-x-1/2 text-[transparent] text-[8rem] md:text-[10rem] lg:text-[18rem] font-normal font-bigNoodle lg:tracking-wider uppercase text-stroke-blue">
          {t("roadmap")}
        </h1>
      </div>

      <div className="absolute opacity-60 inset-0 bg-gradient-to-b from-[#000000] from-0% via-[#0041BF] via-30% to-[#000000] to-100% pointer-events-none z-[-1]"></div>
      <div className="absolute opacity-40 inset-0 bg-gradient-to-b from-[#000000] from-0% via-[#00114E] via-49% to-[#000000] to-100% pointer-events-none z-[-1]"></div>

      <div className="w-[60%] flex flex-col ml-[6rem] lg:ml-0 pt-20 pr-[1.5rem]">
        <img
          className="absolute left-0 h-auto md:h-auto lg:h-[112vh] w-[35vw] md:w-[35vw] lg:w-[auto] mt-[4rem] lg:mt-[-2rem] 2xl:mt-[-4rem]"
          src="/assets/anipaper/anipaper-roadmap-element.webp"
          alt="anipaper roadmap element"
        />
        <div className="flex flex-col justify-center gap-[2rem] h-[45vh] z-[999]">
          <h1 className="text-[#FFF7EA] text-7xl font-normal font-bigNoodle transition-opacity duration-1000">
            {t("animara roadmap")}
          </h1>
          <p className="text-[#FFFFFF] text-left text-sm font-normal font-outfit transition-opacity duration-1000">
            {t("animara roadmap desc")}
          </p>
        </div>
        <div className="flex flex-col my-[1rem] pb-[10rem] lg:pb-[20rem] h-auto">
          {roadmapPointList.map((item, index) => (
            <div
              key={`roadmap-${index}`}
              className="flex flex-col transition-all duration-500 mb-10 lg:mb-16"
              onClick={() => toggleExpand(index)}
            >
              <div className="flex justify-between items-center">
                <div className="flex flex-col lg:flex-row lg:items-center w-[80%]">
                  <h1 className="lg:w-[30%] text-[#FFC85A] text-3xl lg:text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
                    {item.date}
                  </h1>
                  <h1 className="text-[#FFC85A] text-3xl lg:text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
                    {item.title}
                  </h1>
                </div>
                <button
                  className={`z-[999] text-1xl font-bold ml-[1rem] transition-all duration-300 ease-in-out ${expandedIndex === index ? "-rotate-180" : ""}`}
                  onClick={() => toggleExpand(index)}
                >
                  <ChevronDown fill={"#FFAA00"} width="12px" />
                </button>
              </div>
              <div className={`flex justify-end transition-all duration-500 ease-in-out overflow-hidden ${expandedIndex === index ? "h-auto opacity-100" : "max-h-0 opacity-0"}`}>
                <div className="w-full lg:w-[75%] py-[1rem] text-justify mt-2 text-[#FFFFFF] font-outfit font-light">
                  <p className="tracking-wider text-sm lg:text-base leading-normal">
                    {item.desc}
                  </p>
                  <br />
                  <br />
                  {formatData(item.content)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AniPaperRoadmap;