import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainV2Supporter = () => {
  const { t } = useTranslation();

  const cardDetails = {
    0: {
      imgUrl: "/assets/mainV2/support/supporter-2.webp",
      title: "Logo 1"
    },
    1: {
      imgUrl: "/assets/mainV2/support/supporter-3.png",
      title: "Logo 2"
    },
    2: {
      imgUrl: "/assets/mainV2/support/supporter-4.webp",
      title: "Logo 3"
    },
    3: {
      imgUrl: "/assets/mainV2/support/supporter-5.png",
      title: "Logo 4"
    },
    4: {
      imgUrl: "/assets/mainV2/support/supporter-6.webp",
      title: "Logo 5"
    },
    5: {
      imgUrl: "/assets/mainV2/support/supporter-7.webp",
      title: "Logo 6",
    },
    6: {
      imgUrl: "/assets/mainV2/support/supporter-8.webp",
      title: "Logo 7",
    },
    7: {
      imgUrl: "/assets/mainV2/support/supporter-9.webp",
      title: "Logo 8",
    }
  };

  function CarouselItem({ imgUrl, imgTitle }) {
    return (
      <div className="carousel-card w-[80px] lg:w-[150px] h-[80px] lg:h-[150px] lg:ml-[3rem]">
        <img className="w-full h-full" src={imgUrl} alt={imgTitle}></img>
      </div>
    );
  }

  return (
    <div
      className="relative w-screen max-w-[1920px] amax-h-[1080px] flex flex-col justify-end items-center pt-[8rem] z-[1000]"
    >

    {/* Start and End Fade Out Overlays */}
    <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
        <h1 className="text-[#FFFFFF] text-5xl font-normal font-bigNoodle transition-opacity duration-1000 pt-4 lg:pt-20">
          {t('anisupporter title')}
        </h1>
        <div className="w-full h-[22vh] mt-[3rem]">
          <div className="carousel-container">
            <div className="carousel-track gap-[1rem] lg:gap-[1.5rem]">
              {Object.keys(cardDetails).map((detailKey) => {
                return (
                  <CarouselItem
                    key={detailKey}
                    imgUrl={cardDetails[detailKey].imgUrl}
                    imgTitle={cardDetails[detailKey].title}
                  ></CarouselItem>
                );
              })}
              {Object.keys(cardDetails).map((detailKey) => {
                return (
                  <CarouselItem
                    key={detailKey}
                    imgUrl={cardDetails[detailKey].imgUrl}
                    imgTitle={cardDetails[detailKey].title}
                  ></CarouselItem>
                );
              })}
              {Object.keys(cardDetails).map((detailKey) => {
                return (
                  <CarouselItem
                    key={detailKey}
                    imgUrl={cardDetails[detailKey].imgUrl}
                    imgTitle={cardDetails[detailKey].title}
                  ></CarouselItem>
                );
              })}
            </div>
          </div>
        </div>
    </div>
  );
};

export default MainV2Supporter;