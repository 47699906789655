import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MainV2AniWalk = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="relative grid grid-rows-2 lg:grid-rows-1 h-full max-w-[1920px] max-h-[1080px] py-12 w-full content-center bg-[#000000]">

      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    
      <div className="col-start-1 row-start-1 hidden lg:flex h-auto pointer-events-none gradient-overlay">
        <video
          src="https://storage.animara.world/city-walk.webm"
          className="w-auto h-auto max-h-[64rem] ml-auto mr-0"
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
      <div className="col-start-1 row-start-1 lg:hidden grid grid-rows-1">
        <img
          className="col-start-1 row-start-1 w-full h-full object-cover z-[49]"
          src="/assets/mainV2/animal-walk.webp"
          alt="Event Background"
        />
        <div className="col-start-1 row-start-1 flex top-[-1px] left-0 pointer-events-none z-[50]">
          <img
            className="w-full h-full"
            src="/assets/mainV2/Anicity-walk-bg.webp"
            alt="Event Background"
          />
        </div>
      </div>
     
      <div className="relative col-start-1 row-start-2 lg:row-start-1 h-full w-full flex flex-col items-start justify-center px-[3rem] lg:px-[10rem] py-[4rem] lg:py-[8rem] gap-[2rem] z-[100]">
        <div className="flex flex-col w-full lg:w-[40%] gap-[0.3rem]">
          <h1 className="text-[#FFC85A] text-4xl lg:text-5xl 2xl:text-6xl font-normal font-bigNoodle transition-opacity duration-1000">
            {t('aniwalk title 1')}
          </h1>
          <h1 className="w-fit bg-[#0163BE] py-[0.5rem] px-[2rem] text-[#FFFFFF] rounded-[30px] text-3xl lg:text-4xl 2xl:text-5xl font-normal font-bigNoodle transition-opacity duration-1000">
            {t('aniwalk title 2')}
          </h1>
          <h1 className="text-[#FFC85A] text-4xl lg:text-5xl 2xl:text-6xl font-normal font-bigNoodle transition-opacity duration-1000">
            {formatStringBreaks(t('aniwalk title 3'))}
          </h1>
        </div>
        <div className="flex flex-col lg:w-[40%] gap-[1.5rem]">
          <p
            className={`text-white text-sm 2xl:text-sm font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 1")}
          </p>
          <p
            className={`text-[#C5C5C5] text-sm 2xl:text-sm font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 2")}
          </p>
          <p
            className={`text-[#C5C5C5] text-sm 2xl:text-sm font-medium font-outfit transition-opacity duration-1000`}
          >
            {t("aniwalk desc 3")}
          </p>
          <a
            type="button"
            href="https://app.animara.world/login"
            className="w-fit px-[2rem] lg:px-[5rem] py-[1rem] mt-4 bg-[#FFB23F] drop-shadow-lg text-white rounded-full text-base lg:text-lg xl:text-xl font-outfit font-bold transition-all duration-300 hover:scale-105"
          >
            {t("join now")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainV2AniWalk;