import React from "react";

const MusicIcon = ({ fill, width = "24", height = "24" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.34625 2.19961C5.84593 0.79366 7.87913 0.00263754 10 0C12.1209 0.00263754 14.1541 0.79366 15.6537 2.19961C17.1534 3.60556 17.9972 5.51168 18 7.49999C18 12.8844 10.5467 19.6281 10.23 19.9125C10.1684 19.9686 10.0859 20 10 20C9.91411 20 9.8316 19.9686 9.77 19.9125C9.45333 19.6281 2 12.8844 2 7.49999C2.00281 5.51168 2.84657 3.60556 4.34625 2.19961ZM10 13C12.7614 13 15 10.7614 15 8C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8C5 10.7614 7.23858 13 10 13Z"
      fill="white"
    />
  </svg>
);

export default MusicIcon;
