import React, { useCallback, useEffect, useRef, useState } from "react";
import Konva from "konva";
import PinInfo from "./PinInfo";

const AnimaraMap = ({ isShowingPins, setIsPageReady, isMobile, setIsShowingPinToggle }) => {
  const stageRef = useRef(null);
  const backgroundRef = useRef(null);
  const effectsVideoRef = useRef(null);
  const miscEffectVideoRef = useRef(null);
  const videoImageRef = useRef(null);
  const miscVideoImageRef = useRef(null);
  const konvaStageRef = useRef(null); // Use useRef for Konva stage
  const pinsLayerRef = useRef(null); // Use useRef for pins layer
  const [selectedPin, setSelectedPin] = useState(null);
  const [zoomData, setZoomData] = useState(null); // State to store zoom data
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const hoveredPinRef = useRef(null);

  // Function to detect orientation change using window.screen.orientation
  const handleOrientationChange = useCallback(() => {
    const orientation = window.screen.orientation.type;
    console.log(orientation);
    if (orientation.includes("landscape")) {
      setIsLandscape(true); 
    } else {
      setIsLandscape(false); 
    }
  }, []);

  const handleResize = () => {
    if (stageRef.current === null ){ return; }
    if (konvaStageRef.current === null ){ return; }
    console.log(` isIOS(): ${ isIOS()}`);
    console.log(` isMobile: ${ isMobile}`);
    if (isMobile || isIOS()){
      console.log('ho');
      konvaStageRef.current.width(window.innerWidth);
      konvaStageRef.current.height(window.innerHeight);
    }else{
      console.log('hahaha');
      konvaStageRef.current.width(stageRef.current.clientWidth);
      konvaStageRef.current.height(stageRef.current.clientHeight);
    }
    konvaStageRef.current.batchDraw();
  };

  // Function to detect iOS
  const isIOS = () => {
    return (
      /android|iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const animateSingleCloud = useCallback((cloud) => {
    if (!konvaStageRef.current){
      cloud.destroy();
      return;
    }
    // Define the movement path for the cloud
    cloud.to({
      x: cloud.x() + 100, // Move right (adjust distance as needed)
      y: cloud.y() + 50,  // Move down (adjust distance as needed)
      duration: (Math.random() * 4) + 8,
      onFinish: () => {
        if (!konvaStageRef.current){
          cloud.destroy();
          return;
        }
        cloud.to({
          x: cloud.x() - 100, // Move left (adjust distance as needed)
          y: cloud.y() + 50,  // Move down (adjust distance as needed)
          duration: (Math.random() * 4) + 8,
          onFinish: () => {
            if (!konvaStageRef.current){
              cloud.destroy();
              return;
            }
            cloud.to({
              x: cloud.x() - 100, // Move left (adjust distance as needed)
              y: cloud.y() - 50,  // Move up (adjust distance as needed)
              duration: (Math.random() * 4) + 8,
              onFinish: () => {
                if (!konvaStageRef.current){
                  cloud.destroy();
                  return;
                }
                cloud.to({
                  x: cloud.x() + 100, // Move right (adjust distance as needed)
                  y: cloud.y() - 50,  // Move up (adjust distance as needed)
                  duration: (Math.random() * 4) + 8,
                  onFinish: () => {
                    if (!konvaStageRef.current){
                      cloud.destroy();
                      return;
                    }
                    animateSingleCloud(cloud); // Repeat animation
                  }
                });
              }
            });
          }
        });
      }
    });
  },[konvaStageRef]);

  const handlePinClick = useCallback((pinPosition, pinIndex) => {
    if (selectedPin !== null) {
      // If a pin is already selected, zoom out and deselect
      konvaStageRef.current.to({
        scaleX: 1,
        scaleY: 1,
        x: 0,
        y: 0,
        duration: 0.5,
        onFinish: () => {
          setSelectedPin(null);
          setIsShowingPinToggle(true);
        },
      });
    } else {
      // Zoom into the clicked pin
      const scale = 1.5; // Adjust scale factor as needed

      // Calculate new position to center the pin
      let newX = -pinPosition.x * scale + konvaStageRef.current.width() / 2;
      let newY = -pinPosition.y * scale + konvaStageRef.current.height() / 2;

      // Store zoom data
      setZoomData({
        scale: scale,
        x: konvaStageRef.current.x(),
        y: konvaStageRef.current.y(),
      });

      // Adjust position bounds to prevent showing blank areas
      const stageWidth = konvaStageRef.current.width();
      const stageHeight = konvaStageRef.current.height();
      const imageWidth = konvaStageRef.current.getLayers()[0].getChildren()[0].width() * scale;
      const imageHeight = konvaStageRef.current.getLayers()[0].getChildren()[0].height() * scale;

      const minX = Math.min(0, stageWidth - imageWidth);
      const minY = Math.min(0, stageHeight - imageHeight);
      const maxX = Math.max(0, stageWidth - imageWidth);
      const maxY = Math.max(0, stageHeight - imageHeight);

      newX = Math.max(minX, Math.min(maxX, newX));
      newY = Math.max(minY, Math.min(maxY, newY));

      konvaStageRef.current.to({
        scaleX: scale,
        scaleY: scale,
        x: newX,
        y: newY,
        duration: 0.5,
        onFinish: () => {
          setSelectedPin(pinIndex);
          setIsShowingPinToggle(false);
        },
      });
    }
  },[selectedPin, setIsShowingPinToggle]);

  // setup canvas
  const setupCanvas = useCallback(() => {
    Konva.hitOnDragEnabled = true;

    if (konvaStageRef.current){
      konvaStageRef.current.destroy();
    }

    const initialScale = isMobile ? 0.5 : 1.0;
    konvaStageRef.current = new Konva.Stage({
      container: stageRef.current,
      width: stageRef.current.clientWidth,
      height: stageRef.current.clientHeight,
      draggable: true,
      x: 0,
      y: 0,
      scaleX: initialScale, 
      scaleY: initialScale,
    });

    const backgroundLayer = new Konva.Layer();
    const effectsLayer = new Konva.Layer();
    const cloudsLayer = new Konva.Layer();
    const pinsLayer = new Konva.Layer(); // New layer for pins
    pinsLayerRef.current = pinsLayer; // Set pins layer in ref

    konvaStageRef.current.add(backgroundLayer);
    konvaStageRef.current.add(effectsLayer);
    konvaStageRef.current.add(cloudsLayer);
    konvaStageRef.current.add(pinsLayer); // Add pins layer last to ensure it's on top

    // Background layer
    backgroundRef.current = new window.Image();
    if (isMobile) {
      backgroundRef.current.src = "/assets/animara/map-mobile.webp";
    }
    else {
      backgroundRef.current.src = "/assets/animara/map.webp";
    }
    backgroundRef.current.onload = () => {
      const konvaImage = new Konva.Image({
        image: backgroundRef.current,
        width: backgroundRef.current.width,
        height: backgroundRef.current.height,
      });
      backgroundLayer.add(konvaImage);

      // Only play the videos if not mobile
      if (!isMobile && !isIOS()) {
        // Water effect video layer
        effectsVideoRef.current = document.createElement("video");
        effectsVideoRef.current.src = "https://storage.animara.world/animara-map-animations.webm";
        effectsVideoRef.current.loop = true;
        effectsVideoRef.current.muted = true;
        effectsVideoRef.current.playsInLine = true;
        videoImageRef.current = new Konva.Image({
          image: effectsVideoRef.current,
          width: backgroundRef.current.width,
          height: backgroundRef.current.height,
        });
        effectsLayer.add(videoImageRef.current);
        const videoAnim = new Konva.Animation((frame) => {
          // do nothing to here as just having this will update the video
        }, effectsLayer);

        effectsVideoRef.current.addEventListener("play", () => {
          if (videoPlaying) { return; }
          videoAnim.start();
        });

        effectsVideoRef.current.addEventListener("pause", () => {
          videoAnim.stop();
        });
        setVideoPlaying(false);
        effectsVideoRef.current.play();
      }

      // Clouds layer
      const addCloud = (imageSrc, x, y) => {
        const image = new window.Image();
        image.src = imageSrc;
        return new Promise((resolve, reject) => {
          image.onload = () => {
            const konvaImage = new Konva.Image({
              image: image,
              x: x,
              y: y,
              width: image.width,
              height: image.height,
            });
            cloudsLayer.add(konvaImage);
            resolve(konvaImage); // Resolve with Konva.Image object
          };
          image.onerror = (err) => reject(err);
        });
      };

      // Add clouds and start animation after all images are loaded
      Promise.all([
        // Top left
        addCloud("/assets/animara/C01.webp", 1200, 520),
        addCloud("/assets/animara/C05.webp", -60, 15),
        addCloud("/assets/animara/FC_02.webp", 1500, -120),
        addCloud("/assets/animara/FC_03.webp", 200, -300),

        // Top right
        addCloud("/assets/animara/FC_04.webp", 3400, -150),
        addCloud("/assets/animara/C01.webp", 3600, 500),

        // Bottom left
        addCloud("/assets/animara/C06.webp", -150, 1000),
        addCloud("/assets/animara/FC_04.webp", -10, 1500),

        // Bottom right
        addCloud("/assets/animara/C07.webp", 1750, 1500),
        addCloud("/assets/animara/FC_01.webp", 2500, 1200),
        addCloud("/assets/animara/C04.webp", 3300, 1600),
      ]).then(newClouds => {
        cloudsLayer.batchDraw();
        newClouds.forEach((cloud) => {
          animateSingleCloud(cloud);
        })
      });

      // Add pins after map image loads
      addPins(pinsLayer);

      backgroundLayer.batchDraw();

      // Enable dragging after image loads
      konvaStageRef.current.draggable(true);

      // Set drag boundaries within the image
      konvaStageRef.current.setDragBoundFunc((pos) => {
        const stageWidth = konvaStageRef.current.width();
        const stageHeight = konvaStageRef.current.height();
        const imageWidth = backgroundRef.current.width * konvaStageRef.current.scaleX();
        const imageHeight = backgroundRef.current.height * konvaStageRef.current.scaleY();

        const minX = Math.min(0, stageWidth - imageWidth);
        const minY = Math.min(0, stageHeight - imageHeight);
        const maxX = Math.max(0, stageWidth - imageWidth);
        const maxY = Math.max(0, stageHeight - imageHeight);

        let newX = Math.max(minX, Math.min(maxX, pos.x));
        let newY = Math.max(minY, Math.min(maxY, pos.y));
        return { x: newX, y: newY };
      });
    };

    const addPins = (layer) => {
      // Static positions for pins
      const pinPositions = [
        { x: 2360, y: 700 },
        { x: 3280, y: 250 },
        { x: 2950, y: 1050 },
        { x: 1900, y: 1200 },
        { x: 1930, y: 450 },
        { x: 830, y: 600 },
      ];

      const pinImages = [
        "/assets/animara/pins/pin1.webp",
        "/assets/animara/pins/pin2.webp",
        "/assets/animara/pins/pin3.webp",
        "/assets/animara/pins/pin4.webp",
        "/assets/animara/pins/pin5.webp",
        "/assets/animara/pins/pin6.webp",
      ];

      let pins = [];
      pinPositions.forEach((pos, index) => {
        const pinImage = new window.Image();
        pinImage.src = pinImages[index];
        pinImage.onload = () => {
          // Adjust width and height as needed
          const width = 62;
          const height = 72;
          const konvaPin = new Konva.Image({
            image: pinImage,
            width: width, 
            height: height,
            offsetX: width / 2,
            offsetY: height,
            x: pos.x + width / 2,
            y: pos.y + height,
            draggable: false,
          });

          // Attach click event handler to each pin
          konvaPin.on("click tap", () => {
            handlePinClick(pos, index); // Call handlePinClick function with pin position and index
          });

          layer.add(konvaPin);
          layer.batchDraw(); // Ensure the pin is drawn after adding
          pins.push(konvaPin);
        };
      });

      // define hover animations
      const hoverAnim = new Konva.Animation(function (frame) {
        if (!hoveredPinRef.current) { return; }
        // scale x and y
        const hoverEnlargeScale = 1.3;
        hoveredPinRef.current.scale({ x: hoverEnlargeScale, y: hoverEnlargeScale });
      }, layer);

      const unhoverAnim = new Konva.Animation(function (frame) {
        if (!hoveredPinRef.current) { return; }
        // skip anim if clicked
        if (selectedPin === hoveredPinRef.current){
          return;
        }
        // scale x and y
        hoveredPinRef.current.scale({ x: 1.0, y: 1.0 });
      }, layer);

      // start anim on hover
      layer.on('mouseover', function (evt) {
        document.body.style.cursor = 'pointer';
        hoveredPinRef.current = evt.target;
        hoverAnim.start();
      });

      layer.on('mouseout', function (evt) {
        document.body.style.cursor = 'default';
        hoveredPinRef.current = evt.target;
        unhoverAnim.start();
      });

      const centeredScalingTransformer = new Konva.Transformer({
        nodes: pins,
        centeredScaling: true,
      });
      layer.add(centeredScalingTransformer);
    };

    // Function to request full-screen mode and lock orientation
    const lockOrientation = async () => {
      try {
        // Request full-screen mode
        if (document.documentElement.requestFullscreen) {
          await document.documentElement.requestFullscreen();
        } else if (document.documentElement.webkitRequestFullscreen) { // Safari
          await document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
          await document.documentElement.msRequestFullscreen();
        }

        // Lock the orientation once in full-screen mode
        const screenOrientation = window.screen.orientation;
        await screenOrientation.lock("portrait"); 
        console.log(`Screen orientation locked to ${screenOrientation.type}`);
      } catch (error) {
        console.error("Error locking orientation:", error);
      }
    };

    // Only lock orientation on mobile
    if (isMobile ) {
      lockOrientation();
    }

    window.addEventListener("resize", handleResize);

    // setup controls
    if (isMobile) {
      // Multi-touch logic for mobile
      function getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
      }

      function getCenter(p1, p2) {
        return {
          x: (p1.x + p2.x) / 2,
          y: (p1.y + p2.y) / 2,
        };
      }
      let lastCenter = null;
      let lastDist = 0;
      let dragStopped = false;

      konvaStageRef.current.on('touchmove', function (e) {
        e.evt.preventDefault();
        let touch1 = e.evt.touches[0];
        let touch2 = e.evt.touches[1];
      
        // we need to restore dragging, if it was cancelled by multi-touch
        if (touch1 && !touch2 && !konvaStageRef.current.isDragging() && dragStopped) {
          konvaStageRef.current.startDrag();
          dragStopped = false;
        }
      
        if (!touch1 || !touch2) {
          lastDist = null;
          return;
        }
        
        if (konvaStageRef.current.isDragging()) {
          dragStopped = true;
          konvaStageRef.current.stopDrag();
        }
    
        let p1 = { x: touch1.clientX, y: touch1.clientY };
        let p2 = { x: touch2.clientX, y: touch2.clientY };

        // Get current stage position (viewport)
        let viewportX = konvaStageRef.current.x();
        let viewportY = konvaStageRef.current.y();
    
        if (!lastCenter) {
          lastCenter = getCenter(p1, p2, viewportX, viewportY);
          return;
        }
        let newCenter = getCenter(p1, p2);
        let dist = getDistance(p1, p2);
    
        if (!lastDist) {
          lastDist = dist;
        }
    
        let pointTo = {
          x: (newCenter.x - konvaStageRef.current.x()) / konvaStageRef.current.scaleX(),
          y: (newCenter.y - konvaStageRef.current.y()) / konvaStageRef.current.scaleX(),
        };
    
        // New scale calculation
        let scale = konvaStageRef.current.scaleX() * (dist / lastDist);
    
        // Set boundaries for scaling
        const baseMinScale = 0.75;
        const baseMaxScale = 1.25;
        const widthBound = window.innerHeight > window.innerWidth ? false : true;
        const minScale = widthBound ? baseMinScale / 1920 * window.innerWidth : baseMinScale / 1080 * window.innerHeight;
        const maxScale = widthBound ? baseMaxScale / 1920 * window.innerWidth : baseMaxScale / 1080 * window.innerHeight;
        scale = Math.max(minScale, Math.min(maxScale, scale));
    
        konvaStageRef.current.scaleX(scale);
        konvaStageRef.current.scaleY(scale);
    
        let dx = newCenter.x - lastCenter.x;
        let dy = newCenter.y - lastCenter.y;
    
        let newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };
    
        // Restrict the position so it doesn't go out of bounds
        const stageWidth = konvaStageRef.current.width();
        const stageHeight = konvaStageRef.current.height();
        const imageWidth = backgroundRef.current.width * scale;
        const imageHeight = backgroundRef.current.height * scale;
    
        const minX = Math.min(0, stageWidth - imageWidth);
        const minY = Math.min(0, stageHeight - imageHeight);
        const maxX = Math.max(0, stageWidth - imageWidth);
        const maxY = Math.max(0, stageHeight - imageHeight);
    
        newPos.x = Math.max(minX, Math.min(maxX, newPos.x));
        newPos.y = Math.max(minY, Math.min(maxY, newPos.y));
    
        konvaStageRef.current.position(newPos);
    
        lastDist = dist;
        lastCenter = newCenter;
      });
      
    }
    else {
      // Handle zoom with mouse wheel for non-mobile
      const handleWheel = (e) => {
        if (e.evt.preventDefault){
          e.evt.preventDefault();
        }
    
        const scaleBy = 1.05;
        const oldScale = konvaStageRef.current.scaleX();
        const pointer = konvaStageRef.current.getPointerPosition() || {x: 0, y: 0};
    
        let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    
        const baseMinScale = 0.75;
        const baseMaxScale = 1.25;
        const widthBound = window.innerHeight > window.innerWidth ? false : true;
        const minScale = widthBound ? baseMinScale / 1920 * window.innerWidth : baseMinScale / 1080 * window.innerHeight;
        const maxScale = widthBound ? baseMaxScale / 1920 * window.innerWidth : baseMaxScale / 1080 * window.innerHeight;
        newScale = Math.max(minScale, Math.min(maxScale, newScale));
    
        const mousePointTo = {
          x: (pointer.x - konvaStageRef.current.x()) / oldScale,
          y: (pointer.y - konvaStageRef.current.y()) / oldScale,
        };
    
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
    
        const stageWidth = konvaStageRef.current.width();
        const stageHeight = konvaStageRef.current.height();
        const imageWidth = backgroundRef.current.width * newScale;
        const imageHeight = backgroundRef.current.height * newScale;
    
        const minX = Math.min(0, stageWidth - imageWidth);
        const minY = Math.min(0, stageHeight - imageHeight);
        const maxX = Math.max(0, stageWidth - imageWidth);
        const maxY = Math.max(0, stageHeight - imageHeight);
    
        newPos.x = Math.max(minX, Math.min(maxX, newPos.x));
        newPos.y = Math.max(minY, Math.min(maxY, newPos.y));
    
        konvaStageRef.current.scale({ x: newScale, y: newScale });
        konvaStageRef.current.position(newPos);
        konvaStageRef.current.batchDraw();
      };
    
      konvaStageRef.current.on('wheel', handleWheel);
      handleWheel({evt:{
        deltaY: 0,
        deltaX: 0,
      }});
    }

    // center screen to
    console.log(isMobile);
    konvaStageRef.current.to({
      // initial pos calculated based on 1920x1080, perform conversion based on screen size
      x: isMobile ? -990 : -1350, 
      y: isMobile ? -30 : -200,
      // to snap to center instantly
      duration: 0, 
    });
    handleResize();

    return () => {
      // Cleanup function to destroy the stage and prevent memory leaks
      window.removeEventListener("resize", handleResize);
      if (konvaStageRef.current) {
        konvaStageRef.current.destroy(); // Destroy the Konva stage and its layers
        konvaStageRef.current = null;
        pinsLayerRef.current = null;
      }
    };
    
    // DO NOT ADD selectedPin as dependency as it will break the entire canvas when selected pin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Add orientation change listener when component mounts
  useEffect(() => {
    if (isMobile && window.screen.orientation) {
      window.screen.orientation.addEventListener("change", handleOrientationChange);
      handleOrientationChange(); // Set initial orientation

      // Cleanup event listener when component unmounts
      return () => {
        window.screen.orientation.removeEventListener("change", handleOrientationChange);
      };
    }
  }, [isMobile, window.screen.orientation, handleOrientationChange]);

  // initialize
  useEffect(() => {
    // trigger transition
    setIsPageReady(true);

    const setupCanvasTimer = setTimeout(setupCanvas, 100);

    return () => {
      clearTimeout(setupCanvasTimer);

      if (videoImageRef.current){
        videoImageRef.current.remove();
        videoImageRef.current = null;
        console.log('cleanedup videoImageRef');
      }

      if (miscVideoImageRef.current){
        miscVideoImageRef.current.remove();
        miscVideoImageRef.current = null;
        console.log('cleanedup miscVideoImageRef');
      }

      if (effectsVideoRef.current){
        effectsVideoRef.current.remove();
        effectsVideoRef.current = null;
        console.log('cleanedup waterEffectVideoRef');
      }

      if (miscEffectVideoRef.current){
        miscEffectVideoRef.current.remove();
        miscEffectVideoRef.current = null;
        console.log('cleanedup miscEffectVideoRef');
      }

      if (backgroundRef.current){
        backgroundRef.current.remove();
        backgroundRef.current = null;
        console.log('cleanedup backgroundRef');
      }

      if (hoveredPinRef.current){
        hoveredPinRef.current.destroy();
        hoveredPinRef.current = null;
        console.log('cleanedup hoveredPinRef');
      }

      if (pinsLayerRef.current){
        pinsLayerRef.current.destroy();
        pinsLayerRef.current = null;
        console.log('cleanedup pinsLayerRef');
      }

      if (konvaStageRef.current){
        konvaStageRef.current.off("wheel");
        konvaStageRef.current.off("touchmove");
        konvaStageRef.current.off("touchend");
        konvaStageRef.current.destroy();
        konvaStageRef.current = null;
        console.log('cleanedup konvaStageRef');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupCanvas]);

  useEffect(() => {
    if (pinsLayerRef.current) {
      if (isShowingPins) {
        pinsLayerRef.current.visible(true); // Show pins layer
      } else {
        pinsLayerRef.current.visible(false); // Hide pins layer
      }
      konvaStageRef.current.batchDraw(); // Redraw stage to apply changes
    }
  }, [isShowingPins]); // Depend on isShowingPins to update layer visibility

  const closePinInfo = () => {
    // Retrieve zoom data
    const { x, y } = zoomData;
  
    // Disable stage interactions (draggable, touch, etc.)
    konvaStageRef.current.draggable(false);
  
    // Function to disable all touch, pointer, and gesture events
    const disableAllInteractions = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };
  
    // Add listeners to block touch and pointer events (use passive: false to ensure blocking on iOS)
    window.addEventListener('touchstart', disableAllInteractions, { passive: false });
    window.addEventListener('touchmove', disableAllInteractions, { passive: false });
    window.addEventListener('touchend', disableAllInteractions, { passive: false });
    window.addEventListener('pointerdown', disableAllInteractions, { passive: false });
    window.addEventListener('pointermove', disableAllInteractions, { passive: false });
    window.addEventListener('gesturestart', disableAllInteractions, { passive: false });

    document.body.classList.add('no-scroll');

    // Add the no-scroll class dynamically via CSS
    const style = document.createElement('style');
    style.innerHTML = `
      .no-scroll {
        touch-action: none;
        overflow: hidden !important;
        position: fixed; /* Prevents scroll bouncing on iOS */
        width: 100%;
        height: 100%;
      }
    `;
    document.head.appendChild(style);
  
    // Zoom out and return to original position
    konvaStageRef.current.to({
      scaleX: 1,
      scaleY: 1,
      x: x,
      y: y,
      duration: 0.4,
      onFinish: () => {
        // Re-enable stage interactions
        konvaStageRef.current.draggable(true);
  
        // Remove touch and pointer event listeners after the animation is complete
        window.removeEventListener('touchstart', disableAllInteractions);
        window.removeEventListener('touchmove', disableAllInteractions);
        window.removeEventListener('touchend', disableAllInteractions);
        window.removeEventListener('pointerdown', disableAllInteractions);
        window.removeEventListener('pointermove', disableAllInteractions);
        window.removeEventListener('gesturestart', disableAllInteractions);

        // Re-enable scrolling and remove the no-scroll class
        document.body.classList.remove('no-scroll');
        
        // Clean up dynamically added style
        if (style.parentNode) {
          style.parentNode.removeChild(style);
        }
  
        // Reset selected pin and toggle state
        setSelectedPin(null);
        setIsShowingPinToggle(true);
      }
    });
  };
  

  return (
    <>
      {isIOS() && isLandscape ? 
        <div className="absolute inset-0 flex items-center justify-center bg-black z-50 text-white">
          <p>Rotate your device to view the map. </p>
        </div> 
        :
        <></>
      }
      <div ref={stageRef} className="bg-black absolute inset-0 z-0">
        {selectedPin !== null && (
          <PinInfo pinIndex={selectedPin} onClose={closePinInfo} />
        )}
      </div>
    </>
  );
};

export default AnimaraMap;
