import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useVisibility from "../../component/UseVisibility";

const MainV2Referal = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const isVisible = useVisibility(sectionRef, 0.8);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div
      ref={sectionRef}
      className="relative w-screen lg:h-full max-w-[1920px] bg-[#000000] flex mx-auto py-[4rem]"
    >

    {/* Start and End Fade Out Overlays */}
    <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
    <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute top-0 w-full z-[1000]">
        {!isMobile ? (
          <video
            src="https://storage.animara.world/warning-bar.webm"
            className="w-full h-full z-200 scale-[1]"
            autoPlay
            loop
            muted
            playsInline
          />
        ) : (
          <img
              src="/assets/mainV2/warning.webp"
              className="w-full h-full scale-[2] -mt-8"
              alt="Event Background"
            />
        )}
      </div>

      <div className="hidden lg:flex absolute top-0 left-0 pointer-events-none z-[30]">
        <img
            src="/assets/mainV2/mainV2-referal-bg.webp"
            className="w-full h-full"
            alt="background referal"
        />
      </div>

      <div className="flex lg:hidden absolute top-0 left-0 pointer-events-none z-[30]">
        <img
            src="/assets/mainV2/mainV2-referal-bg-mobile.webp"
            className="w-full h-full"
            alt="background referal"
        />
      </div>

      <div className="h-auto lg:h-full px-[3rem] lg:px-[8rem] mt-[40vh] lg:mt-[20vh] xl:mt-[16vh] flex flex-col justify-center py-[4rem] z-[100] container">
        <div className="flex flex-col w-full lg:items-end justify-center gap-[2rem]">
          <div className="flex flex-col lg:w-[30%] gap-[0.3rem]">
            <h1
              className="text-[#FFC85A] text-5xl lg:text-6xl font-normal font-bigNoodle transition-opacity duration-1000"
              style={{ textShadow: '3px 3px 6px rgba(0, 0, 0, 0.5)' }}
            >
              {formatStringBreaks(t('anireferal title 1'))}
            </h1>
            <h1 className="w-fit bg-[#0163BE] py-[0.5rem] px-[2rem] text-[#FFFFFF] rounded-[30px] text-4xl lg:text-5xl font-normal font-bigNoodle transition-opacity duration-1000">
              {t('anireferal title 2')}
            </h1>
          </div>
          <div className="flex flex-col lg:w-[30%] gap-[0.5rem] lg:gap-[1.5rem]">
            <p
              className={`text-white text-base font-medium font-outfit transition-opacity duration-1000 my-[1rem]`}
              style={{ textShadow: '3px 3px 5px rgba(0, 0, 0, 0.75)' }}
            >
              {t("anireferal desc")}
            </p>
            <a 
              type="button"
              href="https://app.animara.world/login"
              className="w-fit px-[3rem] lg:px-[5rem] py-[1rem] mt-4 bg-[#FFB23F] drop-shadow-lg text-white rounded-full text-base lg:text-lg xl:text-xl font-outfit font-bold transition-all duration-300 hover:scale-105"
            >
              {t("claim now")}
            </a>
          </div>
        </div>
        <div className="flex flex-col w-full items-start justify-center gap-[2rem] pt-[32vh] lg:pt-[24vh]">
          <h1
            className="text-[#49DEFF] text-4xl lg:text-[40px] font-normal font-bigNoodle transition-opacity duration-1000"
            style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
          >
            {t('anireferal point title')}
          </h1>
          <div className="flex flex-col lg:flex-row w-full items-start justify-between gap-[3rem] lg:gap-0">
            <div className="flex flex-col lg:w-[30%]">
              <div className="flex items-center gap-[1rem]">
                <img
                  className="w-[40px] lg:w-[50px]"
                  src="/assets/icons/locale-white.webp"
                />
                <div className="flex flex-col">
                  <p 
                    className="text-white text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    01
                  </p>
                  <h1 
                    className="text-[#FFC85A] text-2xl lg:text-3xl font-normal font-bigNoodle transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    {t('anireferal point 1 title')}
                  </h1>
                </div>
              </div>
              <p 
                className="text-[#ffffff] text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000 my-[0.5rem]"
                style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
              >
                {t('anireferal point 1 desc')}
              </p>
            </div>
            <div className="flex flex-col lg:w-[30%]">
              <div className="flex items-center gap-[1rem]">
                <img
                  className="w-[40px] lg:w-[50px]"
                  src="/assets/icons/locale-white.webp"
                />
                <div className="flex flex-col">
                  <p
                    className="text-white text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    02
                  </p>
                  <h1
                    className="text-[#FFC85A] text-2xl lg:text-3xl font-normal font-bigNoodle transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    {t('anireferal point 2 title')}
                  </h1>
                </div>
              </div>
              <p
                className="text-[#ffffff] text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000 my-[0.5rem]"
                style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
              >
                {t('anireferal point 2 desc')}
              </p>
            </div>
            <div className="flex flex-col lg:w-[30%]">
              <div className="flex items-center gap-[1rem]">
                <img
                  className="w-[40px] lg:w-[50px]"
                  src="/assets/icons/locale-white.webp"
                />
                <div className="flex flex-col">
                  <p
                    className="text-white text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    03
                  </p>
                  <h1
                    className="text-[#FFC85A] text-2xl lg:text-3xl font-normal font-bigNoodle transition-opacity duration-1000"
                    style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
                  >
                    {t('anireferal point 3 title')}
                  </h1>
                </div>
              </div>
              <p
                className="text-[#ffffff] text-sm lg:text-base font-medium font-outfit transition-opacity duration-1000 my-[0.5rem]"
                style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.5)' }}
              >
                {t('anireferal point 3 desc')}
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MainV2Referal;
