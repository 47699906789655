import React, { useRef, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";

const AudioPlayerPlaylist = ({ playlist }) => {
  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [isReady, setIsReady] = useState(false);

  const handleClickPrevious = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    setCurrentMusicIndex((prevIndex) =>
      prevIndex === 0 ? playlist.length - 1 : prevIndex - 1
    );
  };

  const handleClickNext = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    setCurrentMusicIndex((prevIndex) =>
      prevIndex < playlist.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePlay = async () => {
    if (audioRef.current && audioRef.current.audio && audioRef.current.audio.current) {
      try {
        await audioRef.current.audio.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.log("Audio play failed:", error);
      }
    }
  };

  const handlePause = () => {
    if (audioRef.current && audioRef.current.audio && audioRef.current.audio.current) {
      audioRef.current.audio.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    if (isReady) {
      handlePlay();
    }
  }, [isReady, currentMusicIndex]);

  useEffect(() => {
    const onPageLoad = () => {
      setIsReady(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  // Global event listener to detect any video play and check if it's muted or not
  useEffect(() => {
    const handleVideoPlay = (event) => {
      if (event.target.tagName === 'VIDEO' && !event.target.muted) {
        handlePause(); // Pause the audio player if the video is playing and not muted
      }
    };

    const handleVideoEnd = (event) => {
      if (event.target.tagName === 'VIDEO') {
        console.log('Video has ended');
        handlePlay();
      }
    };

    // Attach event listener to document to listen for any video playing
    document.addEventListener('play', handleVideoPlay, true); // 'true' to capture the event during the capturing phase
    document.addEventListener('ended', handleVideoEnd, true);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('play', handleVideoPlay, true);
      document.removeEventListener('ended', handleVideoEnd, true);
    };
  }, [isPlaying]);

  // Detect tab visibility changes and stop the music if the tab is hidden
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handlePause(); // Pause the music when the tab is not active
      } else if (document.visibilityState === "visible") {
        
        handlePlay();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (playlist.length === 0) {
    return <div>No songs available in the playlist</div>;
  }

  return (
    <div className="fixed bottom-[2rem] lg:bottom-[4rem] px-[2rem] xl:px-[8rem] z-[9999]">
      <AudioPlayer
        ref={audioRef}
        customProgressBarSection={[]}
        customControlsSection={[
          <div className="relative flex justify-between items-center h-14 duration-300 
            rounded-full p-[2px] bg-gradient-to-b from-[#F4FBFFCC] to-[#78BFF2CC] text-white
            shadow-[0px_1px_10px_0px_rgba(4,161,183,0.4)]"
          >
            <div className="flex h-full w-full bg-[#004280CC] text-white rounded-full items-center justify-center">
              <img
                src={playlist[currentMusicIndex]?.avatar}
                className={`transform duration-200 ml-[0.65rem]
                ${isExpanded
                    ? "h-16 sm:h-20 lg:h-20 -translate-y-[.35rem] sm:-translate-y-[.8rem] lg:-translate-y-[.9rem]"
                    : "h-16 -translate-y-[.35rem]"
                }`}
                alt="animara icon"
              />
              <div className={`musicicon ${isPlaying ? "playingicon" : "pausedicon"} mx-4`}>
                <span />
                <span />
                <span />
              </div>
              <div className={`${isExpanded ? "flex" : "hidden"}`}>
                <div className="text-left w-[4rem] sm:w-[6rem] lg:w-[6rem] pl-2 pr-4 flex flex-col justify-center">
                  <p className="font-bigNoodle uppercase truncate">
                    {playlist[currentMusicIndex]?.name}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-1 mr-3">
                  <button
                    className="h-6 w-6 sm:h-8 sm:w-8 lg:h-8 lg:w-8 rounded-full flex items-center justify-center transform hover:scale-110 active:scale-95 transition-transform outline-none"
                    onClick={handleClickPrevious}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="40"
                      width="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m16 7-7 5 7 5zm-7 5V7H7v10h2z"></path>
                    </svg>
                  </button>
                  <button
                    className="h-6 w-6 sm:w-8 lg:h-8 lg:w-8 rounded-full flex items-center justify-center transform hover:scale-110 active:scale-95 transition-transform outline-none"
                    onClick={isPlaying ? handlePause : handlePlay}
                  >
                    {isPlaying ? (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="40"
                        width="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
                      </svg>
                    ) : (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="40"
                        width="40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 6v12l10-6z"></path>
                      </svg>
                    )}
                  </button>
                  <button
                    className="h-6 w-6 sm:w-8 lg:h-8 lg:w-8 rounded-full flex items-center justify-center transform hover:scale-110 active:scale-95 transition-transform outline-none"
                    onClick={handleClickNext}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="40"
                      width="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 7v10l7-5zm9 10V7h-2v10z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <button
                className="absolute -top-5 -right-5 z-30 h-10 w-10 duration-300 hover:scale-110 active:scale-95 outline-none 
                    transform flex items-center justify-center
                    rounded-full p-[2px] bg-gradient-to-b from-[#F4FBFFCC] to-[#78BFF2CC] text-white"
                onClick={toggleExpand}
              >
                <span className="flex h-full w-full bg-[#003459] text-white rounded-full items-center justify-center">
                  {isExpanded ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-3 fill-current" viewBox="0 0 24 24">
                      <path d="M0 10h24v4h-24z"></path>
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-3 fill-current" viewBox="0 0 24 24">
                      <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"></path>
                    </svg>
                  )}
                </span>
              </button>
            </div>
          </div>,
        ]}
        onEnded={handleClickNext}
        autoPlayAfterSrcChange={true}
        showSkipControls={true}
        showJumpControls={false}
        src={playlist[currentMusicIndex]?.src}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
      />
    </div>
  );
};

export default AudioPlayerPlaylist;