import React from "react";

const VideoPlayer = ({ videoLink, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[100] animate-fadeIn xl:p-[6rem]">
      <div className="relative max-w-[1800px] max-h-[1080px] w-full h-auto lg:h-full rounded-lg">
        <video
          src={videoLink}
          playsInline
          controls
          autoPlay
          className="w-full h-full lg:object-cover"
          onClick={(event) => {
            event.stopPropagation(); 
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
