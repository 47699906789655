import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AniPaperHero = ({ setIsPageReady }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Enhanced mobile detection based on user agent
    const handleResize = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Safari detection based on user agent
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      setIsSafari(true);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isVideoLoaded = useCallback(() => {
    setIsPageReady(true);
  }, [setIsPageReady]);

  return (
    <div className="relative h-[90vh] lg:h-[100vh] w-screen max-w-[1920px] z-[30] mb-10 flex flex-col items-center justify-center bg-[#000000]">

      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute inset-0 z-[1] flex flex-col w-full h-full items-center justify-center mt-[4rem] xs:mt-[2rem]">

        {!isMobile ? (
           isSafari ? (
            <video
              src="https://storage.animara.world/anipaper-hero.mov"
              className="object-fill w-full"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              onCanPlay={isVideoLoaded}
            />
          ) : (
            <video
              src="https://storage.animara.world/anipaper-hero.webm"
              className="object-fill w-full"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              onCanPlay={isVideoLoaded}
            />
          )
        ) : (
         isSafari ? (
          <video
            src="https://storage.animara.world/anipaper-hero-mobile.mp4"
            className="lg:hidden object-cover h-[55%] mx-12"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            onCanPlay={isVideoLoaded}
          />
          ) : (
            <video
              src="https://storage.animara.world/anipaper-hero-mobile.webm"
              className="lg:hidden object-cover h-[55%] mx-12"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              onCanPlay={isVideoLoaded}
            />
          )
        )}

        {/* text content */}
        <div className="w-screen flex flex-col items-center justify-center gap-[2rem] mt-[2rem] lg:mt-[5rem] pb-4">
          <p className="w-[80%] lg:w-[50%] text-[#FFFFFF] text-center text-sm font-normal font-outfit transition-opacity duration-1000">
            {t("anipaper hero desc")}
          </p>
          <div className="flex flex-col lg:flex-row gap-[2rem] text-center scale-[80%] lg:scale-100">
            <a
              type="button"
              href="https://whitepaper.animara.world/"
              target="_blank"
              rel="noreferrer"
              className="px-[2rem] lg:px-[6rem] py-[1rem] bg-[#FFB23F] drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30] hover:scale-105 transition-all duration-300"
            >
              {t("animara white paper")}
            </a>
            <a
              type="button"
              href="https://storage.animara.world/ppt.pdf"
              target="_blank"
              rel="noreferrer"
              className="px-[2rem] lg:px-[6rem] py-[1rem] bg-[#FFB23F] drop-shadow-lg text-white rounded-3xl text-base lg:text-lg xl:text-xl font-outfit font-semibold xl:font-bold z-[30] hover:scale-105 transition-all duration-300"
            >
              {t("animara presentation slide")}
            </a>
          </div>
        </div>
      </div>

      <div className="absolute inset-0 opacity-60 bg-[radial-gradient(circle,_#035D9F_0%,_#000000_80%,_transparent_100%)] lg:bg-[radial-gradient(circle,_#035D9F_0%,_#000000_90%,_transparent_100%)] pointer-events-none bg-[transparent] z-[-1]"></div>

      <div className="absolute opacity-100 w-screen h-[15vh] bottom-0 bg-gradient-to-b from-[transparent] from-0% to-[#000000] to-100% pointer-events-none z-[-1]"></div>
    </div>
  );
};

export default AniPaperHero;
