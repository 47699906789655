// PinInfo.js
import React, { useEffect, useState } from "react";
import VideoPlayer from "../../component/VideoPlayer";
import CrossIcon from "../../component/icons/CrossIcon";
import ChevronDown from "../../component/icons/ChevronDown";
import { useTranslation } from "react-i18next";

const PinInfo = ({ pinIndex, onClose }) => {
  const { t } = useTranslation();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [fullOpacity, setFullOpacity] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); 

  const fieldList = [
    {
      index: 0,
      width: "w-full",
      margin: "mt-[0rem] lg:-mt-[4rem]",
      title: t("main field title 1"),
      intro: t("main field intro 1"),
      gameTitle: t("main field game title 1"),
      description: t("main field description 1"),
      videoLink: 'https://storage.animara.world/opening-trailer.mp4',
    },
    {
      index: 1,
      width: "w-full",
      margin: "mt-[0rem] lg:mt-[4rem]",
      title: t("main field title 2"),
      intro: t("main field intro 2"),
      gameTitle: t("main field game title 2"),
      description: t("main field description 2"),
      videoLink: '',
    },
    {
      index: 2,
      width: "w-full",
      margin: "mt-[0rem] lg:-mt-[8rem]",
      title: t("main field title 3"),
      intro: t("main field intro 3"),
      gameTitle: t("main field game title 3"),
      description: t("main field description 3"),
      videoLink: '',
    },
    {
      index: 3,
      width: "w-full",
      margin: "",
      title: t("main field title 4"),
      intro: t("main field intro 4"),
      gameTitle: t("main field game title 4"),
      description: t("main field description 4"),
      videoLink: '',
    },
    {
      index: 4,
      width: "w-full",
      margin: "",
      title: t("main field title 5"),
      intro: t("main field intro 5"),
      gameTitle: t("main field game title 5"),
      description: t("main field description 5"),
      videoLink: '',
    },
    {
      index: 5,
      width: "w-full",
      margin: "",
      title: t("main field title 6"),
      intro: t("main field intro 6"),
      gameTitle: t("main field game title 6"),
      description: t("main field description 6"),
      videoLink: '',
    },
  ];

  const toggleExpand = (event) => {
    event.stopPropagation(); 
    setIsExpanded((prev) => !prev);
  };

  const handleImageClick = (videoLink) => {
    setSelectedVideo(videoLink);
  };

  const closeVideoPlayer = () => {
    setSelectedVideo(null);
  };

  const fieldData = fieldList.find((field) => field.index === pinIndex);

  const formatDescription = (description) => {
    if (!description) return null;
    return description.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const detectMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    detectMobile();
    window.addEventListener('resize', detectMobile);

    const updateVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    updateVh();
    window.addEventListener('resize', updateVh);

    setTimeout(() => {
      setFullOpacity(true);
    }, 100);

    return () => {
      window.removeEventListener('resize', detectMobile);
      window.removeEventListener('resize', updateVh);
    };
  }, []);

  useEffect(() => {
    
    if (fullOpacity) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [fullOpacity]); 

  const handleCloseScene = () => {
    if (!fullOpacity) return;
    setFullOpacity(false);
    setTimeout(() => {
      onClose()
    }, 1000);
  };

  if (pinIndex === null) return null;

  return (
    <div className={`w-full absolute inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[100] transition-all duration-1000
        ${fullOpacity ? `opacity-100` : `opacity-0`}`}
        onClick={handleCloseScene}>

      {/* left black fill */}
      <div className="w-full h-full bg-black z-[1001]"></div>

      {/* map pin */}
      <div className="flex h-full w-full min-w-screen max-w-[1920px]">

        {/* Start Fade Out Overlay */}
        <div className="w-[1rem] lg:w-[100px] h-full mr-[-1rem] lg:mr-[-100px] bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[11]"></div>
        
        {/* map pin content */}
        {fieldData ? (
          <>
            <img
              src={`/assets/animara/city/animara-city-${fieldData.index}.webp`}
              alt="city details"
              className="w-screen max-w-[1920px] inset-0 object-cover place-content-center h-screen bg-cover bg-no-repeat"
            />
            
            <div className="w-full max-w-[1920px] absolute -bottom-1 lg:bottom-[2rem] flex items-center lg:px-[10rem] lg:py-[8rem] ">
              <div
                className={`
                  ${isExpanded ? "w-full h-[390px] lg:h-[275px]" : "w-[600px] h-[250px] lg:h-[140px]"}
                  bg-gradient-to-b from-[#035D9FCC] to-[#000305CC] bg-opacity-80 flex flex-col rounded-3xl items-start lg:items-center transition-all duration-500
                `}
              >
                <div className="w-full px-[3rem] py-[2rem] lg:px-[2.5rem] lg:py-[2.5rem]" 
                  onClick={(event) => {
                      event.stopPropagation();
                    }}>
                  <div className="flex flex-row items-center justify-between pb-[1rem]">
                    <div className="flex flex-row items-center ">
                      <h1 className="text-white text-2xl lg:text-3xl xl:text-4xl font-normal font-bigNoodle uppercase">
                        {fieldData.title} 
                      </h1>
                      <img
                        src="/assets/icons/video-play.webp"
                        alt="play video button"
                        className={`w-8 lg:w-12 h-8 lg:h-12 ml-[0.5rem] transition-all duration-300 hover:scale-110 ${!isMobile ? fieldData.videoLink === '' ? 'hidden' : '' : 'hidden'}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleImageClick(fieldData.videoLink);
                        }}
                      />
                    </div>

                    {/* ChevronDown for toggling intro visibility */}
                    <div className={`transition-all duration-300 hover:scale-125 ${isExpanded ? "rotate-180" : "rotate-0"}`} onClick={toggleExpand}>
                      <ChevronDown
                        className="z-[999] text-1xl font-bold ml-[1rem] transition-transform duration-300 ease-in-out"
                        fill={"#FFFFFF"}
                        width="16px"
                      />
                    </div>
                  </div>

                  {/* Conditionally render the intro text */}
                  <div className={`transition-opacity duration-700 ${isExpanded ? "opacity-100 delay-500" : "opacity-0"
                    }`}
                  >
                    <p className="max-h-[100px] text-white text-sm lg:text-md xl:text-lg font-normal font-outfit overflow-y-auto outline-none scrollbar-webkit-blue ">
                      {formatDescription(fieldData.intro)}
                    </p>
                </div>
              </div>

                <div className={`w-full flex justify-end ${isMobile ? fieldData.videoLink === '' ? 'hidden' : '' : 'hidden'}`}>
                  <img
                    src="/assets/icons/video-play.webp"
                    alt="play video button"
                    className={`
                      w-16 h-16 mr-[2rem] transition-all duration-700 hover:scale-110 
                      ${isExpanded ? "opacity-100" : "opacity-0"}
                    `}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleImageClick(fieldData.videoLink);
                    }}
                    
                  />
                </div>
              </div>
            </div>

            {selectedVideo !== null && (
              <VideoPlayer
                videoLink={selectedVideo}
                onClose={closeVideoPlayer}
              />
            )}
          </>
        ) : (
          <div className="h-full w-full flex justify-center items-center">
             <button onClick={handleCloseScene} className="absolute top-[4rem] xl:top-[10rem] right-[1rem] xl:right-[10rem] z-50 hover:scale-120 transition-all duration-300">
              <CrossIcon />
            </button>
          </div>
        )}

        {/* End Fade Out Overlay */}
        <div className="w-[1rem] lg:w-[100px] h-full ml-[-1rem] lg:ml-[-100px] bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[11]"></div>
      </div>

      {/* right black fill */}
      <div className="w-full h-full bg-black z-[1001]"></div>
    </div>
  );
};

export default PinInfo;
